import React from 'react'
import styled, { css } from 'styled-components'
import Button from '../../atoms/Button/Button'
import Heading from '../../atoms/Heading/Heading'
import IconDropdownClose from '../../atoms/Icons/IconDropdownClose'

const Wrapper = styled.div`
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   background-color: ${({ theme }) => `${theme.color.black}30`};
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 200;
   animation-duration: 0.2s;
   animation-name: opacity;
   animation-timing-function: ease-in-out;
   @keyframes opacity {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }
`

const Inner = styled.div`
   padding: 2rem;
   border-radius: 12px;
   box-shadow: ${({ theme }) => theme.shadow1.normal};
   position: absolute;
   background-color: ${({ theme }) => theme.color.white};
   width: 80%;
   max-width: 700px;
   animation-duration: 0.2s;
   animation-name: hop;
   animation-timing-function: ease-in-out;
   overflow-y: hidden;
   @keyframes hop {
      from {
         transform: translateY(120px);
      }
      to {
         transform: translateY(0px);
      }
   }
   ${({ big }) =>
      big &&
      css`
         width: 90%;
         height: 90%;
         max-height: 700px;
         max-width: 1248px;
         padding: 4rem;
         @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
            padding: 3rem;
         }
         @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
            padding: 2rem;
         }
      `}
`
const Header = styled.div`
   display: flex;
   justify-content: space-between;
   padding-bottom: 1rem;
`
const CloseWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
`
const Content = styled.div`
   overflow-y: scroll;
   height: 100%;
   &::-webkit-scrollbar {
      display: none;
   }
`

const Modal = ({ big, children, title, clickFn }) => {
   return (
      <Wrapper>
         <CloseWrapper onClick={clickFn} />
         <Inner big={big}>
            <Header>
               <Heading as="h3" size="h3">
                  {title}
               </Heading>
               <Button small onClick={clickFn} secondary square black>
                  <IconDropdownClose color="black" width="16" height="16" />
               </Button>
            </Header>
            <Content>{children}</Content>
         </Inner>
      </Wrapper>
   )
}

export default Modal
