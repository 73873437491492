import React from 'react'
import styled from 'styled-components'
import Box from '../Box/Box'
import Paragraph from '../Paragraph/Paragraph'
import IconInfo from '../Icons/IconInfo'

const StyledBox = styled(Box)`
   background-color: ${({ theme }) => theme.color.redLight};
   color: ${({ theme }) => theme.color.red};
`
const Wrapper = styled.div`
   display: flex;
   align-items: center;
   gap: 1rem;
   svg {
      flex-shrink: 0;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      flex-direction: column;
   }
`

const ErrorBox = ({ children }) => (
   <StyledBox>
      <Wrapper>
         <IconInfo color="red" color2="redLight" />
         <Paragraph color="red">{children}</Paragraph>
      </Wrapper>
   </StyledBox>
)

export default ErrorBox
