import * as React from 'react'
import styled from 'styled-components'
import Footer from '../organisms/Footer/Footer'
import Header from '../organisms/Header/Header'

const Main = styled.main`
   overflow: hidden;
   min-height: calc(100vh - 160px);
`

const Layout = ({ children, url }) => {
   return (
      <>
         {!url.includes('/id/') && <Header />}

         <Main>{children}</Main>
         <Footer />
      </>
   )
}

export default Layout
