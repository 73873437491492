import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../../provider'
import Portal from '../../../../portal'
import Paragraph from '../../atoms/Paragraph/Paragraph'
import Button from '../../atoms/Button/Button'
import IconDropdownClose from '../../atoms/Icons/IconDropdownClose'
import IconCoffee from '../../atoms/Icons/IconCoffee'

const Wrapper = styled.div`
   width: 100%;
   padding: 1rem 1rem;
   background-color: ${({ theme }) => theme.color.primary};
   box-shadow: 3px -8px 15px rgba(230, 166, 145, 0.15),
      1px -1px 5px rgba(235, 188, 161, 0.12);
   position: fixed;
   bottom: 0;
   left: 0;
   z-index: 999;
   display: flex;
   justify-content: center;
   gap: 2rem;
   align-items: center;
   animation-duration: 0.3s;
   animation-name: hop;
   animation-timing-function: ease-in-out;
   @keyframes hop {
      from {
         transform: translateY(100%);
      }
      to {
         transform: translateY(0px);
      }
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
   }
`
const StyledButton = styled(Button)`
   position: absolute;
   right: 1rem;
   top: 50%;
   transform: translateY(-50%);
   &:hover {
      background-color: ${({ theme }) => `${theme.color.white}20`};
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      display: none;
   }
`
const BlackButton = styled(Button)`
   background-color: ${({ theme }) => theme.color.black};
`

const CloseButton = styled(Button)`
   padding: 0;
   @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
      display: none;
   }
`

const Cta = () => {
   const context = useContext(myContext)
   const [open, setOpen] = useState(false)
   useEffect(() => {
      setTimeout(() => {
         setOpen(true)
      }, 5000)
   })
   const clickFn = () => {
      setOpen(false)
      context.closeCta()
   }
   return (
      <>
         {open && context.isCtaOpen && (
            <Portal>
               <Wrapper>
                  <Paragraph color="white">
                     Chcesz więcej darmowych narzędzi SEO? Postaw mi symboliczną
                     kawę :)
                  </Paragraph>
                  <BlackButton
                     small
                     as="a"
                     href="https://www.buymeacoffee.com/marekfoltas"
                     target="_blank"
                  >
                     <IconCoffee />
                     Postaw mi kawę!
                  </BlackButton>
                  <StyledButton onClick={clickFn} small secondary square black>
                     <IconDropdownClose color="white" width="16" height="16" />
                  </StyledButton>
                  <CloseButton small black secondary onClick={clickFn}>
                     Zamknij
                  </CloseButton>
               </Wrapper>
            </Portal>
         )}
      </>
   )
}

export default Cta
