import styled from 'styled-components'
import PropTypes from 'prop-types'

const Paragraph = styled.p`
   font-size: ${({ theme, size }) => theme.fontSize[size]};
   color: ${({ theme, color }) => theme.color[color]};
   a {
      color: ${({ theme, color }) =>
         color === 'white' ? theme.color.white : theme.color.primary};
      text-decoration: underline;
   }
`
export default Paragraph

Paragraph.propTypes = {
   size: PropTypes.string,
   color: PropTypes.string,
}

Paragraph.defaultProps = {
   size: 'normal',
   color: 'black',
}
