import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconShare = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="18"
         height="16"
         viewBox="0 0 18 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33203 10.2042V14.2221L16.2687 8.67278C17.2695 7.87213 17.2695 6.34996 16.2687 5.54931L9.33203 0V4.02734L8.88294 4.02735C3.97703 4.02735 0 8.00437 0 12.9103C0 13.4556 0.00437344 13.9577 0.00875045 14.4602C0.0130942 14.9588 0.0174414 15.4579 0.0175212 16C1.27257 12.6173 5.06354 10.2068 8.88294 10.2068L9.33203 10.2042Z"
            fill={theme.color[color]}
         />
      </svg>
   )
}

export default IconShare

IconShare.propTypes = {
   color: PropTypes.string,
}

IconShare.defaultProps = {
   color: 'black',
}
