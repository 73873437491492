import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const NavigationWrapper = styled.nav``
const NavigationList = styled.ul`
   display: flex;
   gap: 2rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: none;
   }
`
const NavigationItem = styled.li`
   a {
      font-weight: 500;
      color: ${({ theme }) => theme.color.black};
   }
   &:hover a {
      color: ${({ theme }) => theme.color.primary};
   }
`
const Navigation = () => (
   <NavigationWrapper>
      <NavigationList>
         <NavigationItem>
            <AnchorLink to="#czym-jest-drzewko" title="Czym jest drzewko">
               Czym jest drzewko
            </AnchorLink>
         </NavigationItem>
         <NavigationItem>
            <AnchorLink to="#jak-to-dziala" title="Jak to działa">
               Jak to działa
            </AnchorLink>
         </NavigationItem>
         <NavigationItem>
            <AnchorLink to="#faq" title="FAQ">
               FAQ
            </AnchorLink>
         </NavigationItem>
      </NavigationList>
   </NavigationWrapper>
)

export default Navigation
