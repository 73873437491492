import React from 'react'
import styled from 'styled-components'
import {
   FacebookShareButton,
   LinkedinShareButton,
   TwitterShareButton,
   FacebookIcon,
   LinkedinIcon,
   TwitterIcon,
} from 'react-share'

const Wrapper = styled.div`
   display: flex;
   justify-content: center;
   padding: 2rem 0;
   button {
      overflow: hidden;
   }
   svg {
      border-radius: 12px;
      width: 48px;
      height: 48px;
   }
`
const Inner = styled.div`
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   align-items: center;
   padding: 1rem 2rem;
   border-radius: 12px;
   transition: ${({ theme }) => theme.transition};
   &:hover {
      background-color: ${({ theme }) => theme.color.grayLight};
   }
`
const SocialShare = () => {
   const url = window?.location.href
   const desc =
      'Proste narzędzie do wizualizacji drzewa kategorii na podstawie arkusza Google Sheets.'
   return (
      <Wrapper>
         <FacebookShareButton url={url} quote={desc}>
            <Inner>
               <FacebookIcon />
               Facebook
            </Inner>
         </FacebookShareButton>
         <LinkedinShareButton
            url={url}
            title="Drzewko.io"
            summary={desc}
            source="Drzewko.io"
         >
            <Inner>
               <LinkedinIcon />
               LinkedIn
            </Inner>
         </LinkedinShareButton>
         <TwitterShareButton url={url} title={desc}>
            <Inner>
               <TwitterIcon />
               Twitter
            </Inner>
         </TwitterShareButton>
      </Wrapper>
   )
}

export default SocialShare
