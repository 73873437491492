import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import IconInfo from '../../atoms/Icons/IconInfo'
import Portal from '../../../../portal'

const Wrapper = styled.div`
   width: ${({ icon }) => (icon ? '2rem' : 'auto')};
   height: ${({ icon }) => (icon ? '2rem' : 'auto')};
`

const Tooltip = styled.div`
   position: fixed;
   width: 300px;
   padding: 1rem;
   background-color: ${({ theme }) => theme.color.blueLight};
   border-radius: 200px;
   z-index: 999;
   left: ${({ left }) => `${left}px`};
   top: ${({ top }) => `${top}px`};
   transform: translate(calc(-50% + 1rem), calc(-100% - 1.5rem));
   opacity: 1;
   font-size: 14px;
   display: flex;
   gap: 1rem;
   align-items: center;
   transition: 0.2s opacity ease-in-out, 0.2s visibility ease-in-out;
   opacity: 0;
   visibility: hidden;
   ${({ reverse }) =>
      reverse &&
      css`
         transform: ${({ height }) =>
            `translate(calc(-50% + 4rem), calc(${height}px + 1.5rem))`};
         :before {
            top: -0.5rem;
         }
      `}
   &.active {
      opacity: 1;
      visibility: visible;
   }
   svg {
      flex-shrink: 0;
   }
   :before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      background-color: ${({ theme }) => theme.color.blueLight};
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      bottom: -0.5rem;
   }
`

const Info = ({ children, content, icon, reverse }) => {
   const ref = useRef()
   const [position, setPosition] = useState({
      left: -500,
      top: 0,
   })
   const [height, setHeight] = useState(0)
   const [hover, setHover] = useState(false)
   const hoverHandler = () => setHover(!hover)
   const tooltipPosition = () => {
      const left = ref.current.getBoundingClientRect().left
      const top = ref.current.getBoundingClientRect().top
      const height = ref.current.clientHeight
      setPosition({
         left: left,
         top: top,
      })
      setHeight(height)
   }
   return (
      <Wrapper>
         <div
            ref={ref}
            onMouseEnter={(e) => {
               hoverHandler(e)
               tooltipPosition()
            }}
            onMouseLeave={hoverHandler}
         >
            {icon && (
               <IconInfo
                  ref={ref}
                  onMouseEnter={(e) => {
                     hoverHandler(e)
                     tooltipPosition()
                  }}
                  onMouseLeave={hoverHandler}
               />
            )}
            {children}
         </div>

         <Portal>
            <Tooltip
               height={height}
               reverse={reverse}
               left={position.left}
               top={position.top}
               className={`${hover ? 'active' : ''}`}
            >
               <IconInfo color="blue" color2="blueLight" />
               <span>{content}</span>
            </Tooltip>
         </Portal>
      </Wrapper>
   )
}

export default Info
