import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconDropdownClose = ({ color, width, height }) => {
   const theme = useTheme()
   return (
      <svg
         width={width}
         height={height}
         viewBox="0 0 10 10"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path d="M1 9L9 1" stroke={theme.color[color]} strokeWidth="2" />
         <path
            d="M0.999999 1L9 9"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
      </svg>
   )
}

export default IconDropdownClose

IconDropdownClose.propTypes = {
   color: PropTypes.string,
   width: PropTypes.string,
   height: PropTypes.string,
}

IconDropdownClose.defaultProps = {
   color: 'white',
   width: '10',
   height: '10',
}
