import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.a`
   display: flex;
   overflow: hidden;
   align-items: center;
   gap: 0.5rem;
   width: max-content;
   background-color: ${({ theme }) => theme.color.primary};
   color: ${({ theme }) => theme.color.white};
   font-weight: 500;
   padding: 1rem 2rem;
   border-radius: 12px;
   box-shadow: ${({ theme }) => theme.shadow1.normal};
   transition: ${({ theme }) => theme.transition};
   font-size: 1rem;
   position: relative;
   &:hover {
      box-shadow: ${({ theme }) => theme.shadow1.hover};
   }
   svg path {
      stroke: ${({ theme, arrow }) => arrow && theme.color.white};
   }
   ${({ secondary }) =>
      secondary &&
      css`
         background-color: transparent;
         color: ${({ theme }) => theme.color.primary};
         box-shadow: none;
         svg path {
            stroke: ${({ theme, arrow }) => arrow && theme.color.primary};
         }
         &:hover {
            background-color: ${({ theme }) => theme.color.primaryLight};
            box-shadow: none;
         }
      `}
   ${({ small }) =>
      small &&
      css`
         padding: ${({ square }) => (square ? '0.5rem' : '0.5rem 1rem')};
      `}
   ${({ black }) =>
      black &&
      css`
         color: ${({ theme }) => theme.color.black};
         svg path {
            stroke: ${({ theme, arrow }) => arrow && theme.color.black};
         }
         &:hover {
            background-color: ${({ theme }) => theme.color.grayLight};
         }
      `}
      ${({ blackSecond }) =>
      blackSecond &&
      css`
         &:hover {
            background-color: ${({ theme }) => theme.color.primaryLight};
         }
      `}
`
export default Button

Button.propTypes = {
   secondary: PropTypes.bool,
}

Button.defaultProps = {
   secondary: false,
}
