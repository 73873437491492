import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

const IconInfo = React.forwardRef(({ color, color2 }, ref) => {
   const theme = useTheme()
   return (
      <svg
         ref={ref}
         width="32"
         height="32"
         viewBox="0 0 32 32"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <circle cx="16" cy="16" r="16" fill={theme.color[color]} />
         <path
            d="M16.0311 11.4743C15.513 11.4743 15.0711 11.3143 14.7054 10.9943C14.3549 10.659 14.1797 10.24 14.1797 9.73714C14.1797 9.21905 14.3549 8.8 14.7054 8.48C15.0711 8.16 15.513 8 16.0311 8C16.5492 8 16.9835 8.16 17.334 8.48C17.6845 8.8 17.8597 9.21905 17.8597 9.73714C17.8597 10.24 17.6845 10.659 17.334 10.9943C16.9835 11.3143 16.5492 11.4743 16.0311 11.4743ZM13.334 24V23.7714L13.654 23.68C13.9587 23.5886 14.1645 23.4514 14.2711 23.2686C14.393 23.0857 14.454 22.8419 14.454 22.5371V15.3829C14.454 15.0629 14.393 14.8267 14.2711 14.6743C14.1645 14.5067 13.9587 14.3924 13.654 14.3314L13.334 14.2629V14.0343L17.5168 12.6857L17.7454 12.9143L17.6768 16.16V22.56C17.6768 22.8648 17.7302 23.1086 17.8368 23.2914C17.9587 23.4743 18.1645 23.6114 18.454 23.7029L18.6826 23.7714V24H13.334Z"
            fill={theme.color[color2]}
         />
      </svg>
   )
})

export default IconInfo

IconInfo.propTypes = {
   color: PropTypes.string,
   color2: PropTypes.string,
}

IconInfo.defaultProps = {
   color: 'black',
   color2: 'grayLight',
}
