export const fetchData = async (id, different = null) => {
   const url = different || `https://opensheet.elk.sh/${id}/Kategorie`
   const result = await fetch(url)
      .then((response) => {
         if (response.ok) {
            return response.json()
         }
         throw new Error('Something went wrong')
      })
      .then((responseJson) => {
         return responseJson
      })
      .catch((error) => {
         console.log(error)
      })
   return result
}

export const createMenu = (data) => {
   const result = []
   let firstId = ''
   let secondId = ''
   let thirdId = ''
   data.map((item) => (item.children = []))
   data.forEach((el) => {
      if (el['Nazwa'] === 'Kategoria') {
         firstId = el['ID']
         result.push(el)
      } else if (el['Nazwa'] === 'Podkategoria 1') {
         secondId = el['ID']
         result.find((item) => item['ID'] === firstId).children.push(el)
      } else if (el['Nazwa'] === 'Podkategoria 2') {
         thirdId = el['ID']
         result
            .find((item) => item['ID'] === firstId)
            .children.find((item) => item['ID'] === secondId)
            .children.push(el)
      } else if (el['Nazwa'] === 'Podkategoria 3') {
         result
            .find((item) => item['ID'] === firstId)
            .children.find((item) => item['ID'] === secondId)
            .children.find((item) => item['ID'] === thirdId)
            .children.push(el)
      }
   })
   return result
}
