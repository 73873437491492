import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Navigation from '../../molecules/Navigation/Navigation'
import Container from '../../atoms/Container/Container'
import logo from '../../../assets/images/logo.svg'
import Button from '../../atoms/Button/Button'
import IconShare from '../../atoms/Icons/IconShare'
import Modal from '../../molecules/Modal/Modal'
import SocialShare from '../SocialShare/SocialShare'

const HeaderWrapper = styled.header`
   width: 100%;
   padding: 1.5rem 0;
   background-color: ${({ theme }) => theme.color.white};
   position: absolute;
   border-bottom: 2px solid ${({ theme }) => theme.color.grayLight};
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      position: relative;
   }
`
const InnerWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   .header__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 1rem 0;
      .share-button {
         display: none;
      }
   }
`

const Header = () => {
   const [open, setOpen] = useState(false)
   const clickHandler = () => setOpen(!open)
   return (
      <HeaderWrapper>
         <Container>
            <InnerWrapper>
               <Navigation />
               <Link to="/" className="header__logo">
                  <img
                     src={logo}
                     alt="Drzewko.io - wizualizacja drzewa kategorii"
                  />
               </Link>
               <Button
                  onClick={clickHandler}
                  className="share-button"
                  small
                  black
                  secondary
               >
                  <IconShare className="fill nostroke" />
                  Podaj dalej!
               </Button>
            </InnerWrapper>
         </Container>
         {open && (
            <Modal clickFn={clickHandler} title="Podziel się z innymi!">
               <SocialShare />
            </Modal>
         )}
      </HeaderWrapper>
   )
}

export default Header
