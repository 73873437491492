import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Box = styled.div`
   padding: 2rem;
   border-radius: 12px;
   background-color: ${({ theme, color }) => theme.color[color]};
   ${({ big }) =>
      big &&
      css`
         padding: 5rem;
         @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
            padding: 3rem;
         }
         @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
            padding: 2rem;
         }
      `}
   ${({ hover }) =>
      hover &&
      css`
         transition: ${({ theme }) => theme.transition};
         &:hover {
            transform: translateY(-0.5rem);
            box-shadow: ${({ theme }) => theme.shadow1.hover};
         }
      `}
`
export default Box

Box.propTypes = {
   color: PropTypes.string,
   big: PropTypes.bool,
}

Box.defaultProps = {
   color: 'grayLight',
   big: false,
}
