import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import IconGithub from '../../atoms/Icons/IconGithub'
import IconLinkedin from '../../atoms/Icons/IconLinkedin'
import Cta from '../../molecules/Cta/Cta'

const Wrapper = styled.footer`
   background-color: ${({ theme }) => theme.color.black};
   padding: 4rem 0;
   color: ${({ theme }) => theme.color.white};
`
const Inner = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1rem;
`

const Footer = () => {
   return (
      <Wrapper>
         <Container>
            <Inner>
               <a
                  href="https://github.com/marekfoltanski"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <IconGithub />
               </a>
               <a
                  href="https://www.linkedin.com/in/marek-folta%C5%84ski-a727aa144/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <IconLinkedin />
               </a>
            </Inner>
         </Container>
         <Cta />
      </Wrapper>
   )
}

export default Footer
